/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // I really dont like empty <p>'s
        function removeEmptyP() {
          $('p').each(function() {
            var $this = $(this);
            if($this.html().replace(/\s|&nbsp;/g, '').length === 0) {
              $this.remove();
            }
          });
          console.log('Executed removeEmptyP.');
        }
        removeEmptyP();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function addAltText() {
          $(".image-container").each(function() {
            newAltText = $(this).find('.subhead-image').html();
            $(this).find('img').prop("alt", newAltText);
          });
          console.log('Executed addAltText.');
        }
        addAltText();

        // Make mobile menu
        function makeMobile() {
          var scroll_height = $('#menu-main').height();

          $('.burger').click(function() {


            if ($(this).hasClass('checked')) {
              //$( "#burger-check" ).prop( "checked", false );
              $('#menu-main').animate({
                'max-height': -scroll_height
              }, 1000, function() {
                $('.burger').toggleClass('checked');
                $('#menu-main').toggleClass('toggled');
              });
              console.log('uncheck');
            } else {
              //$( "#burger-check" ).prop( "checked", true );
              $('#menu-main').animate({
                'max-height': 0
              }, 1000, function() {
                $('.burger').toggleClass('checked');
                $('#menu-main').toggleClass('toggled');
              });
              console.log('check');
            }
          });
        }

        // This handles NAV submenu states
        function navMenuStateHandeling() {

          // This makes Main Menu clickable
          $('#menu-main > .menu-item-has-children').on('click', function (e) {
            if ($(this).hasClass('opend')) {
              //e.preventDefault();
              $(this).find('.sub-menu').hide();
              $(this).removeClass('opend');
            } else {
              e.preventDefault();
              $('.opend').find('.sub-menu').hide();
              $(this).addClass('opend').find('.sub-menu').show();
            }
          });
          console.log('Executed navMenuStateHandeling.');
        }

        if ($(window).width() < 1025) {
          //makeMobile();
          navMenuStateHandeling();
          console.log('Initiated mobile menu.');
        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        function addGhostToPageHome() {
          $('<div id="ghosty"><img src="/wp-content/themes/rohwergut-theme/dist/images/ghosty6.gif"></div>;').insertAfter('.sticky-element-original');
          console.log('addGhostToPage() executed.');
        }
        addGhostToPageHome();
      }
    },
    // About us page, note the change from about-us to about_us.
    'publikationen': {
      init: function() {
        // JavaScript to be fired on the about us page
        function setCarouselWidth() {
          if ($(window).width() < 992) {
            var width = $('.carousel').parent().width();
            widthelement = width * 0.95;
            $('.carousel li').css('width', widthelement);
            console.log('setCarouselWidth() executed.');
          }
        }
        setCarouselWidth();
        $( window ).resize(function() {
          setCarouselWidth();
        });

        function addUfoToPageID7() {
          if (jQuery('body').hasClass('page-id-7')) {
            jQuery('<div id="ufo16"><img src="/wp-content/themes/rohwergut-theme/dist/images/ufo16.gif"></div>;').insertAfter('.sticky-element-original');
          }
          console.log('addUfoToPage() executed.');
        }
        addUfoToPageID7();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $( window ).unload(function() {
    return "Bye now!";
  });

})(jQuery); // Fully reference jQuery after this point.
